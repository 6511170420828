import React, { useContext, Component } from 'react'
import { GlobalDataContext } from '../../context/context'

import { ButtonContent } from "../global/boton/ButtonContent";


//creacion componente para los bloque de servicios
class Box extends Component {
  render() {
    return (
      <div className="w-full h-full"
        data-aos={this.props.animateScrolls}
        data-aos-duration="2000"
      >
        <div className={`${this.props.svgBGClass} h-[500px] bg-cover bg-center bg-no-repeat relative bgOverlayColorBefore `}
          style={{
            backgroundImage: `url("${this.props.bgImages}")`
          }}
        >
          <div className={`relative h-full w-full text-center flex flex-col ${this.props.positionDiv} items-center p-10`}>
            <h3 className="text-white text-[25px] md:text-[30px] text-center font-bold pb-5">
              {this.props.name}
            </h3>
            <ButtonContent btnclases={'botonWhite'} btnStyle={'three'} />
          </div>
        </div>
      </div>
    )
  }
}


const ServicesHome = () => {

  const { rpdata } = useContext(GlobalDataContext)


  return (
    <section className='mb-20'>
      <div className='text-center px-10 py-20'>
        <h1>Our Services</h1>
        <ButtonContent btnLink={'services'} btnName={'View All Services'} />
      </div>

      <div className="w-4/5 grid grid-cols-1 md:grid-cols-3 gap-5 mx-auto pb-5">
        {
          rpdata?.dbServices?.slice(0, 3).map((item, index) => {
            return (
              <Box
                key={index}
                bgImages={item.description[0].img}
                name={item.name}
                svgBGClass={'svgHexagon'}
                positionDiv='justify-end'
                animateScrolls="zoom-in-up"
              />
            )
          })
        }
      </div>

      <div className="w-4/5 grid grid-cols-1 md:grid-cols-3 gap-5 mx-auto ">
        {
          rpdata?.dbServices?.slice(3, 6).map((item, index) => {
            return (
              <Box
                key={index}
                bgImages={item.description[0].img}
                name={item.name}
                svgBGClass={'svgHexagonInverso'}
                positionDiv='justify-center'
                animateScrolls="zoom-in-down"
              />
            )
          })
        }
      </div>
    </section>
  )
}

export default ServicesHome